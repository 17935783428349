import { Button, Checkbox, Col, Drawer, Form, Input, InputNumber, Row, Select, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Option } from 'antd/es/mentions';
import { useEffect, useState } from 'react';
import { ActionType } from '../../constants/actionType';
import { createEpisode, updateEpisode } from '../../helpers/api/story';

const episodeDrawerValidation = {
    name: [
        { required: true, message: 'Name is required' },
        { max: 100, message: 'Name must be less than 100 characters' },
    ],
    developmentDirection: [
        { required: true, message: 'Development direction is required' },
        { max: 5000, message: 'Development direction must be less than 5000 characters' },
    ],
    goal: [
        { required: true, message: 'Goal is required' },
        { max: 5000, message: 'Goal must be less than 5000 characters' },
    ],
    displayedGoal: [
        { required: true, message: 'Displayed Goal is required' },
        { max: 2000, message: 'Displayed Goal must be less than 2000 characters' },
    ],
    openLineOwner: [
        { required: true, message: 'Open line owner is required' },
    ],
    openLine: [
        { max: 5000, message: 'Open line must be less than 5000 characters' },
    ],
    explanation: [
        { max: 5000, message: 'Explanation must be less than 5000 characters' },
    ],
    description: [
        { required: true, message: 'Description is required' },
        { max: 5000, message: 'Description must be less than 5000 characters' },
    ],
    shortDescription: [
        { required: true, message: 'Short description is required' },
        { max: 5000, message: 'Short description must be less than 5000 characters' },
    ],
    status: [
        { required: true, message: 'Status is required' },
    ],
    level: [
        { required: true, message: 'Level is required' },
    ],
    expectedChatNumber: [
        { required: true, message: 'Expected chat number is required' },
    ],
    price: [
        { required: true, message: 'Price is required' },
    ],
}

const EpisodeDrawer = ({
    story,
    drawerVisibility,
    setdDrawerVisibility,
    episodeDetail,
    setEpisodeDetail,
    characters,
    setCharacters,
    actionType,
    setActionType,
    onSuccess,
    onFailure
}: any) => {

    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);

    const [submittables, setSubmittable] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

    const [status, setStatus] = useState<string>("inactive");
    const [previousContinued, setPreviousContinued] = useState<boolean>(true);
    const [openLineOwner, setOpenLineOwner] = useState<string>("inactive");
    const [price, setPrice] = useState<number>(100);

    const [api, contextHolder] = notification.useNotification();
    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(
                () => { setSubmittable(true) },
                () => { setSubmittable(false) },
            );
    }, [formValueObj]);

    useEffect(() => {
        if (actionType === ActionType.CREATE) {
            form.resetFields();
            setSubmittable(false);
            form.setFieldValue("status", "inactive");
            form.setFieldValue("previousContinued", true);
        }
        setEpisodePrice();
    }, [actionType]);

    useEffect(() => {
        if (!episodeDetail) {
            form.setFieldValue("status", "inactive");
            form.setFieldValue("previousContinued", true);
            return;
        }
        setStatus(episodeDetail.status);
        setPreviousContinued(episodeDetail.previousContinued);
        form.setFieldsValue({ ...episodeDetail });
        setEpisodePrice();
    }, [episodeDetail]);

    const setEpisodePrice = () => {
        switch (story.rarity) {
            case 1:
                setPrice(100);
                form.setFieldValue("price", 100);
                break;
            case 2:
                setPrice(200);
                form.setFieldValue("price", 200);
                break;
            case 3:
                setPrice(300);
                form.setFieldValue("price", 300);
                break;
            case 4:
                setPrice(400);
                form.setFieldValue("price", 400);
                break;
            case 5:
                setPrice(500);
                form.setFieldValue("price", 500);
                break;
        }
    }

    const onClose = () => {
        form.resetFields();
        setEpisodeDetail(null);
        setCharacters([]);
        form.setFieldValue("status", "inactive");
        form.setFieldValue("previousContinued", true);
        setSubmittable(false);
        setdDrawerVisibility(false);
        setActionType("");
        setPreviousContinued(true);
    }

    const saveEpisode = async () => {
        try {
            setLoadingBtn(true);
            form.validateFields();
            const openLineOwnerTmp = formValueObj.openLineOwner?.trim();
            const openLine = formValueObj.openLine?.trim();
            const char = characters.filter((c: any) => c.id === openLineOwnerTmp)?.[0];
            if (char?.type === "ai_character" && (openLine?.trim() === "" || openLine === undefined || openLine === null)) {
                showError("Open line is required when open line owner is an AI character");
                setLoadingBtn(false);
                return;
            }
            let response;
            const requestModel: any = {
                name: formValueObj.name?.trim(),
                status: status,
                level: formValueObj.level || 1,
                goal: formValueObj.goal?.trim(),
                displayedGoal: formValueObj.displayedGoal?.trim(),
                description: formValueObj.description?.trim(),
                shortDescription: formValueObj.shortDescription?.trim(),
                openLine: openLine,
                openLineOwner: openLineOwnerTmp,
                explanation: formValueObj.explanation?.trim(),
                expectedChatNumber: formValueObj.expectedChatNumber || 1,
                previousContinued: previousContinued,
                price: formValueObj.price
            }
            if (actionType === ActionType.CREATE) {
                response = await createEpisode(story.id, requestModel);
            } else {
                response = await updateEpisode(story.id, episodeDetail.id, requestModel);
            }

            setLoadingBtn(false);
            if (response?.status === 200) {
                onSuccess();
                onClose();
                return;
            }
        } catch (err) {
            console.log(err);
            onFailure(err);
        }
        setLoadingBtn(false);
    }

    return characters && (
        <Drawer
            title={(actionType === ActionType.UPDATE ? "Update" : "Create") + " a episode"}
            className='character-drawer'
            width={750}
            onClose={() => { setdDrawerVisibility(false) }}
            open={drawerVisibility}
            closeIcon={false}
            footer={
                <div className='d-flex justify-content-center'>
                    <Button onClick={saveEpisode} type="primary" className='me-3' disabled={!submittables} loading={loadingBtn}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            }
        >
            {contextHolder}
            <Form layout="vertical" autoComplete='off' form={form}>
                <Row gutter={16} className='mb-3'>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={episodeDrawerValidation.name}
                        >
                            <Input placeholder="Please enter name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="status"
                            label="Status"
                            rules={episodeDrawerValidation.status}
                        >
                            <Select placeholder="Please select a status" value={status} onChange={(e) => setStatus(e)}>
                                <Option value="active">Active</Option>
                                <Option value="inactive">Inactive</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="level"
                            label="Level"
                            rules={episodeDrawerValidation.level}
                        >
                            <InputNumber placeholder="Please enter level" min={1} max={100} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="expectedChatNumber"
                            label="Expected chat number"
                            rules={episodeDrawerValidation.expectedChatNumber}
                        >
                            <InputNumber placeholder="Please enter expected chat number" min={1} max={1000000} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="previousContinued"
                            label="Continue previous episode"
                        >
                            <Checkbox checked={previousContinued} onChange={(e) => setPreviousContinued(e.target.checked)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="price"
                            label="Price (gems)"
                            rules={episodeDrawerValidation.price}
                        >
                            <InputNumber style={{ width: "100%" }} disabled={true} value={price}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="goal"
                            label="Goal"
                            rules={episodeDrawerValidation.goal}
                        >
                            <TextArea rows={4} placeholder="Enter goal" maxLength={5000} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="displayedGoal"
                            label="Displayed Goal"
                            rules={episodeDrawerValidation.displayedGoal}
                        >
                            <TextArea rows={4} placeholder="Enter displayed goal" maxLength={2000} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="openLineOwner"
                            label="Open line owner"
                            rules={episodeDrawerValidation.openLineOwner}
                        >
                            <Select placeholder="Please select open line owner" value={openLineOwner} onChange={(e) => setOpenLineOwner(e)}>
                                {
                                    characters?.map((item: any) => (
                                        <Option value={item.id}>{item.name + " - " + item.type}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="openLine"
                            label="Open line"
                            rules={episodeDrawerValidation.openLine}
                            help="Open Line is required when Open Line Owner is an AI character"
                        >
                            <TextArea rows={4} placeholder="Enter open line" maxLength={5000} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className='mt-2'>
                    <Col span={24}>
                        <Form.Item
                            name="explanation"
                            label="Explanation"
                            rules={episodeDrawerValidation.explanation}
                        >
                            <TextArea rows={4} placeholder="Enter explanation" maxLength={5000} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={episodeDrawerValidation.description}
                        >
                            <TextArea rows={4} placeholder="Enter description" maxLength={5000} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="shortDescription"
                            label="Short description"
                            rules={episodeDrawerValidation.shortDescription}
                        >
                            <TextArea rows={4} placeholder="Enter short description" maxLength={5000} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )

}

export default EpisodeDrawer;
